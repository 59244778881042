import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import AOS from "aos";
import { Modal } from "bootstrap";

gsap.registerPlugin(ScrollToPlugin);

/*
-------------------------------
Animaciones scroll
-------------------------------
*/
AOS.init();

let scrollRef = 0;
window.addEventListener("scroll", function () {
	// increase value up to 10, then refresh AOS
	if (scrollRef <= 10) {
		scrollRef++;
	} else {
		AOS.refresh();
	}
});

document.addEventListener("DOMContentLoaded", function () {
	/*
	--------------------------------
	Scrolling lenis
	--------------------------------
	*/
	const lenis = new Lenis({
		lerp: 0.1,
		smoothWheel: true,
	});

	// lenis.on("scroll", (e) => {
	// 	console.log(e);
	// });

	function raf(time) {
		lenis.raf(time);
		requestAnimationFrame(raf);
	}

	requestAnimationFrame(raf);

	/*
	--------------------------------
	Menu
	--------------------------------
	*/
	let menuStatus = false;
	const navToggle = document.querySelector(".site-header .nav-toggle button");

	navToggle.addEventListener("click", (e) => {
		e.preventDefault();

		if (!menuStatus) {
			menuStatus = true;
			menuTimeline.timeScale(1).play();
			document.querySelector(".menu-overlay").classList.add("is-active");
			document.querySelector(".site-header").classList.add("is-active");
			document.querySelector("body").classList.add("overhidden");
			navToggle.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" fill="none" viewBox="0 0 17 18"><path stroke="#000" d="M15.91 1.354.354 16.91m0-16.264L15.91 16.203"/></svg><span>Cerrar</span>`;
		} else {
			menuStatus = false;
			menuTimeline.timeScale(2).reverse();
			document.querySelector("body").classList.remove("overhidden");
			navToggle.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="9" fill="none" viewBox="0 0 22 9"><path stroke="#000" d="M0 .5h22m-22 4h22m-22 4h22"/></svg><span>Menú</span>`;
		}
	});

	const menuTimeline = gsap.timeline({
		paused: true,
		onReverseComplete: function () {
			document.querySelector(".menu-overlay").classList.remove("is-active");
			document.querySelector(".site-header").classList.remove("is-active");
		},
	});

	gsap.set(".menu-overlay .menu-bg", { height: "0%" });

	menuTimeline
		.to(".menu-overlay .menu-bg", {
			height: "100%",
			duration: 1,
			ease: "power3.out",
		})
		.from(
			".menu nav ul li",
			{ opacity: 0, stagger: 0.1, x: -20, ease: "power3.out" },
			"<"
		)
		.from(
			".menu-overlay .side",
			{
				opacity: 0,
				x: -20,
				ease: "power3.out",
			},
			"<"
		)
		.from(
			".menu-overlay .side .loc",
			{
				opacity: 0,
				stagger: 0.1,
				x: -20,
				ease: "power3.out",
			},
			"<=50%"
		)
		.from(
			".menu-overlay .cc",
			{ opacity: 0, duration: 1, x: 100, ease: "power3.out" },
			"<=50%"
		);

	/*
	--------------------------------
	Menu contacto
	--------------------------------
	*/
	const navItems = document.querySelectorAll(".menu-overlay nav ul li a");

	navItems[4].addEventListener("click", (e) => {
		e.preventDefault();
		if (menuStatus) {
			menuStatus = false;
			menuTimeline.timeScale(2).reverse();
			document.querySelector("body").classList.remove("overhidden");
			navToggle.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="9" fill="none" viewBox="0 0 22 9"><path stroke="#000" d="M0 .5h22m-22 4h22m-22 4h22"/></svg><span>Menu</span>`;
			setTimeout(() => {
				gsap.to(window, { duration: 1, scrollTo: "#contacto" });
			}, 500);
		}
	});

	const headerContactoBtn = document.querySelector(".site-header .extra a");
	headerContactoBtn.addEventListener("click", (e) => {
		e.preventDefault();
		if (menuStatus) {
			menuStatus = false;
			menuTimeline.timeScale(2).reverse();
			document.querySelector("body").classList.remove("overhidden");
			navToggle.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="9" fill="none" viewBox="0 0 22 9"><path stroke="#000" d="M0 .5h22m-22 4h22m-22 4h22"/></svg><span>Menu</span>`;
			setTimeout(() => {
				gsap.to(window, { duration: 1, scrollTo: "#contacto" });
			}, 500);
		} else {
			gsap.to(window, { duration: 1, scrollTo: "#contacto" });
		}
	});

	/*
	-------------------------------
	Validar forma
	-------------------------------
	*/
	const defaultForm = document.querySelector("#formaContacto");
	if (defaultForm) {
		const nombre = defaultForm.querySelector("#nombre");
		const email = defaultForm.querySelector("#email");
		const tel = defaultForm.querySelector("#tel");
		const location = defaultForm.querySelector("#location");
		location.value = window.location.href;

		function validateNumberInput(event) {
			const key = event.key;
			const allowedKeys = [
				"0",
				"1",
				"2",
				"3",
				"4",
				"5",
				"6",
				"7",
				"8",
				"9",
				".",
				"-",
			];

			if (!allowedKeys.includes(key)) {
				event.preventDefault();
			}
		}

		tel.addEventListener("keydown", validateNumberInput);

		defaultForm.addEventListener("submit", function (e) {
			if (
				nombre.value === "" ||
				nombre.value === null ||
				nombre.value === false
			) {
				e.preventDefault();
				nombre.classList.add("is-invalid");
			} else {
				nombre.classList.remove("is-invalid");
			}

			if (
				email.value === "" ||
				email.value === null ||
				email.value === false
			) {
				e.preventDefault();
				email.classList.add("is-invalid");
			} else {
				email.classList.remove("is-invalid");
			}

			if (tel.value === "" || tel.value === null || tel.value === false) {
				e.preventDefault();
				tel.classList.add("is-invalid");
			} else {
				tel.classList.remove("is-invalid");
			}
		});
	}

	/*
	--------------------------------
	Popup modal
	--------------------------------
	*/
	// setTimeout(() => {
	// 	var myModal = new Modal(document.getElementById("exampleModal"));
	// 	myModal.show();
	// }, 2000);
});
